import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { SurveySuccessIcon } from '~/components/NpsSurvey/NpsSurvey.styles';
import { BasketResponseModel } from '~/dataModels/BasketModel';
import { BasketLineRequestModel } from '~/dataModels/BasketModel/BasketLineRequestModel';
import {
  addBasketLineItemService,
  deleteBasketLineService,
  getBasketResponseService,
  getNewBasketIdService,
  removeBasketPromotion,
  validatePromoService,
} from '~/services/BasketService';
import { getEmptyBasket } from '~/stateManagement/localStorage/basket';

export interface StateResponse {
  message?: string;
  error?: string;
  success?: boolean;
  basketId?: string;
}
//TODO: remove error and message if not needed
interface CartState {
  basket: BasketResponseModel;
  isLoading: boolean;
  isGift: boolean;
  error?: string;
  message?: string;
  getBasketId: (apiUrl: string) => Promise<StateResponse>;
  loadBasket: (apiUrl: string, basketId: string) => Promise<StateResponse>;
  addBasketLine: (
    apiUrl: string,
    basketId: string,
    basketLine: BasketLineRequestModel,
  ) => Promise<StateResponse>;
  deleteBasketLine: (
    apiUrl: string,
    basketId: string,
    basketLineId: string,
  ) => Promise<StateResponse>;
  applyPromo: (
    apiUrl: string,
    basketId: string,
    promoCode: string,
  ) => Promise<StateResponse>;
  removePromo: (apiUrl: string, basketId: string) => Promise<StateResponse>;
  getTotalItems: () => number;
  resetCartStore: () => void;
  //   removeItem: (id: string) => void;
  //   getTotalItems: () => number;
}

const initialCartState = {
  basket: getEmptyBasket(),
  isLoading: false,
  isGift: false,
  error: undefined,
  message: undefined,
};

export const useCartStore = create<CartState>()(
  persist(
    (set, get) => ({
      ...initialCartState,
      getBasketId: async (apiUrl: string) => {
        try {
          set({ isLoading: true });
          const response = await getNewBasketIdService(apiUrl);
          set((state) => ({
            basket: { ...state.basket, basketId: response },
            isLoading: false,
          }));
          return { success: true, basketId: response };
        } catch (error) {
          console.error('error getting basket id:', error);
          set({ isLoading: false });
          return { success: false, error: 'Failed to retrieve basket ID' };
        }
      },
      loadBasket: async (apiUrl: string, basketId: string) => {
        if (!basketId) {
          return {
            success: false,
            error: 'basket id is required to load the basket',
          };
        } else {
          const response = await getBasketResponseService(apiUrl, basketId);

          if (response.success) {
            set({
              basket: response.result?.basket,
            });
            return { success: true, basketId: basketId };
          } else {
            return {
              success: false,
              error: 'Failed to get basket id from server',
            };
          }
        }
      },
      addBasketLine: async (
        apiUrl: string,
        basketId: string,
        basketLine: BasketLineRequestModel,
      ) => {
        set({ isLoading: true });
        if (!basketId) {
          const newIdResponse = await getNewBasketIdService(apiUrl);

          if (newIdResponse) {
            basketId = newIdResponse;
            set((state) => ({
              basket: { ...state.basket, basketId: basketId },
            }));
          } else {
            return {
              success: false,
              error: 'Failed to load basket id from server',
            };
          }
        }

        const result = await getBasketResponseService(apiUrl, basketId);
        if (
          result &&
          result.result &&
          result.result.basket &&
          result.result.basket.basketLines &&
          result.result.basket.basketLines.length > 0
        ) {
          const basketLineExist = result.result.basket.basketLines.find(
            (bl: any) =>
              bl.combination.toUpperCase() ===
              basketLine.combination.toUpperCase(),
          );

          if (basketLineExist) {
            await deleteBasketLineService(
              apiUrl,
              basketId,
              basketLineExist.basketLineId,
            );
          }
        }

        await addBasketLineItemService(apiUrl, basketId, basketLine);

        const basketResponse = await getBasketResponseService(apiUrl, basketId);

        if (basketResponse.success) {
          const currentBasket = basketResponse.result?.basket;
          set({
            basket: currentBasket,
            isLoading: false,
          });
          return { success: true, basketId: currentBasket?.basketId };
        } else {
          set({
            isLoading: false,
          });
          return { success: false, error: 'Failed to load basket from server' };
        }
      },
      deleteBasketLine: async (
        apiUrl: string,
        basketId: string,
        basketLineId: string,
      ) => {
        set({ isLoading: true });
        const response = await deleteBasketLineService(
          apiUrl,
          basketId,
          basketLineId,
        );
        if (response === true) {
          const basketResponse = await getBasketResponseService(
            apiUrl,
            basketId,
          );
          if (basketResponse.success) {
            set({
              basket: basketResponse.result?.basket,
              isLoading: false,
            });
            return { success: true };
          } else {
            set({
              isLoading: false,
            });
            return { success: false, error: 'Failed to Load basket' };
          }
        } else {
          set({ isLoading: false });
          return { success: false, error: 'Failed to delete the basket' };
        }
      },
      applyPromo: async (
        apiUrl: string,
        basketId: string,
        promoCode: string,
      ) => {
        let stateResponse: StateResponse = { success: false };
        const result = await validatePromoService(apiUrl, promoCode, basketId);
        
        if (result.IsValid) {
          const response = await getBasketResponseService(apiUrl, basketId);
          
          if (response.success) {
            set({
              basket: response.result?.basket,
            });
            stateResponse.message = result.RedemptionMessage;
            stateResponse.success = true;
          } else {
            stateResponse.error = result.Reason;
          }
        } else {
          stateResponse.error = result.Reason;
        }
        return stateResponse;
      },
      removePromo: async (apiUrl: string, basketId: string) => {
        const result = await removeBasketPromotion(apiUrl, basketId);
        if (result === false) {
          return {
            success: false,
            error: 'Failed to remove promo code from basket',
          };
        }
        const response = await getBasketResponseService(apiUrl, basketId);

        if (response.success) {
          set({
            basket: response.result?.basket,
          });
          return { success: true };
        } else {
          set({ isLoading: false });
          return { success: false, error: 'Failed to load basket' };
        }
      },
      resetCartStore: () => set(initialCartState),
      getTotalItems: () => get().basket.basketLines.length,
      //   addItem: (item) => set((state) => ({ items: [...state., item] })),
      //   removeItem: (id) =>
      //     set((state) => ({
      //       items: state.items.filter((item) => item.id !== id),
      //     })),
      // getTotalItems: () =>
      //   get().items.reduce((acc, item) => acc + item.quantity, 0),
    }),
    {
      name: 'cart-storage', // Key in storage
      storage: createJSONStorage(() => localStorage), // Persist in sessionStorage
    },
  ),
);
